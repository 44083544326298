<template>
	<div class="my-account">
		<div class="header-menu va-row">
			<div class="flex md10 offset--md1">
				<div class="header-teacher">
					<div class="va-row">
						<div class="flex xs6 ddh-item">
							Dentro Educa – {{ teacherCredential.user.client_name }}
						</div>
						<div class="flex xs6 ddh-prof">
							<router-link class :to="{ name: 'main' }">
								Encerrar sessão
								<i class="fa fa-sign-out" aria-hidden="true"></i>
							</router-link>

							<img
								:src="teacherCredential.user.client_logo"
								class="logo-client"
								alt="logo"
								width="120"
							/>
						</div>
					</div>

					<div class="va-row">
						<div class="flex xs12 text-left school-name">
							<h4>
								<router-link :to="{ name: 'grades' }">
									<span class="back-circle">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="36"
											height="36"
											viewBox="0 0 36 36"
										>
											<path
												fill="#AFA2BA"
												fill-rule="evenodd"
												stroke="#AFA2BA"
												stroke-linecap="round"
												stroke-linejoin="round"
												stroke-width="1.6"
												d="M15.123 11.294a1.004 1.004 0 0 1 0 1.42l-4.282 4.282h17.156a1.003 1.003 0 1 1 0 2.007H10.84l4.282 4.284a1.004 1.004 0 0 1-1.419 1.42L7 18l6.705-6.706a1.003 1.003 0 0 1 1.418 0z"
											/>
										</svg>
									</span>
								</router-link>
								<!-- {{ teacherCredential.name }} -->
								Adicionar ou remover alunos
							</h4>
						</div>
					</div>
				</div>
			</div>

			<div class="teacher-grade va-row" v-if="teacherCredential">
				<div class="flex md10 offset--md1 xs12">
					<div class="va-row">
						<div class="flex md6 school-name-short">
							<span v-if="grade.name"
								>{{ grade.name }} <span>•</span> Status:
								{{ grade.status }}</span
							>
						</div>
						<!-- <div class="flex md6 xs12 text-right">
							<router-link
								class="btn btn-primary"
								target="_blank"
								:to="{ name: 'LoginStudent' }"
							>
								ÁREA DO ALUNO
								<span class="fa fa-external-link"></span>
							</router-link>
						</div> -->
					</div>
				</div>
			</div>
		</div>

		<div class="body-teacher">
			<div class="va-row">
				<div class="flex md12 text-center">
					<h1 class="add-title">Adicionar ou remover alunos</h1>
				</div>

				<div class="flex md8 offset--md2">
					<div class="tab">
						<button
							:class="upload ? '' : 'active'"
							@click="changeUploadStatus(false)"
						>
							Cadastro individual
						</button>
						<!-- <button
							id="btn-insert-xlsx"
							:class="upload ? 'active' : ''"
							@click="changeUploadStatus(true)"
						>
							Cadastro Multiplo de alunos
						</button> -->
					</div>

					<div class="body-spacing">
						<div class="tab-item" v-if="!upload">
							<div class="panel">
								<!-- <h4>
									{{ grade.name }} <span>•</span> Status: {{ grade.status }}
								</h4> -->

								<form @submit.prevent="saveStudent">
									<div class="">
										<div class="va-row">
											<div
												class="flex md12 xs12 text-left no-padding-left name-field"
											>
												<label>Nome do aluno</label>

												<input-normal
													type="text"
													label="Nome do aluno"
													name="name"
													maxlength="120"
													id="input-name-student"
													v-validate="'required'"
													:disabled="false"
													:data-vv-as="'nome'"
													:value="student.name"
													@input="student.name = $event.target.value"
												/>

												<div class="error">{{ errors.first("name") }}</div>
											</div>

											<div
												class="flex md6 xs12 text-left birthday-field"
												v-if="showBirthDay"
											>
												<label>Data de nascimento (Opcional)</label>
												<div class="va-row">
													<div class="flex xs3 no-padding-top no-padding-left">
														<select-normal
															:items="days"
															label="Dia"
															name="day"
															:value="selectedBirthDay"
															@change="onChangeDay($event)"
														/>
													</div>
													<div class="flex xs5 no-padding-top">
														<select-normal
															:items="months"
															label="Mês"
															name="month"
															:value="selectedBirthMonth"
															@change="onChangeMonth($event)"
														/>
													</div>
													<div class="flex xs4 no-padding-top">
														<select-normal
															:items="years"
															label="Ano"
															name="year"
															v-if="!isOtherYear"
															:value="selectedBirthYear"
															@change="onChangeYear($event)"
														/>

														<input-normal
															type="text"
															label="Ano"
															name="year"
															v-if="isOtherYear"
															:disabled="false"
															:data-vv-as="'ano'"
															:value="selectedBirthYear"
															@input="selectedBirthYear = $event.target.value"
														/>
													</div>
												</div>
												<div class="error">{{ errors.first("birthdate") }}</div>
											</div>

											<div class="flex xs12 text-right">
												<div class="error text-center">
													{{ errors.first("error") }}
												</div>
												<button id="btn-add-student" type="submit" class="btn">
													ADICIONAR ALUNO
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>

							<div class="va-row custom-teacher-table">
								<div
									class="flex xs12 header-table"
									v-if="grade.students.length > 0"
								>
									<div class="va-row">
										<div class="flex xs12">ALUNOS ADICIONADOS</div>
									</div>
								</div>

								<div
									class="flex xs12 body-student"
									v-for="(student, index) in grade.students"
									:key="student.id"
								>
									<div class="va-row">
										<div class="flex xs11 student-name">
											{{ student.name }}
										</div>
										<div
											class="flex xs4 student-name text-right"
											v-if="showBirthDay"
										>
											{{ student.birthdate }}
										</div>
										<div class="flex xs1 text-right">
											<button
												id="btn-trash-student"
												class="btn-trash"
												@click="deleteStudent(student.id, index)"
											>
												<span
													id="span-trash-student"
													class="fa fa-trash"
												></span>
											</button>
										</div>
									</div>
								</div>
							</div>

							<div
								class="phrase-secure va-row"
								v-if="grade.students.length > 0"
							>
								<div class="flex xs6"></div>
								<div class="flex xs6 text-right">
									<router-link
										class="btn"
										:disabled="grade.students.length > 0"
										:to="{ name: 'grade', params: { grade: grade } }"
									>
										CONCLUIR
									</router-link>
								</div>
							</div>
						</div>

						<div class="tab-item flex xs12" v-if="upload">
							<div class="">
								<div class="panel" v-if="uploadStep === 1">
									<b
										><span class="step-label">Passo 1</span> - Baixe o arquivo
										modelo</b
									>

									<p>
										Aqui você pode cadastrar automaticamente vários alunos de
										uma única vez.<br /><br />
										O arquivo para baixar é um CSV que você poderá preencher no
										Excel. <br />Dentro desse aquivo você irá preencher o nomes
										dos alunos da turma.
									</p>

									<a
										href="https://docs.google.com/spreadsheets/d/17sI80zczG9E-yMVeyvvnwKx6fctneaJPYI5tiNMhvJc/edit#gid=0"
										target="_blank"
										class="btn btn-primary btn-medium cancel"
									>
										BAIXAR ARQUIVO MODELO <span class="fa fa-file"></span
									></a>

									<!-- <div class="va-row">
										<div class="flex xs6">
											<b>1. Formato do arquivo: para importação</b>
											Arquivo CSV (disponível na planilha do excel)

											<br /><br />

											<b>2. Colunas</b> (campo obrigatório):

											<ul>
												<li>Nome do Aluno</li>
												<li>Data de Nascimento</li>
											</ul>

											<br />
											<b>3. Exemplo de como preencher a lista</b>
										</div>

										<div class="flex xs12">
											<div class="upload-box">
												<h3>Baixe o arquivo-modelo aqui</h3>
												<a
													href="https://docs.google.com/spreadsheets/d/17sI80zczG9E-yMVeyvvnwKx6fctneaJPYI5tiNMhvJc/edit#gid=0"
													target="_blank"
													class="btn btn-primary cancel"
													><span class="fa fa-chevron-down"></span> BAIXAR</a
												>
											</div>
										</div>

										<div class="flex md12 text-right">
											<button
												id="btn-next"
												@click="changeStepsUpload(2)"
												class="btn right"
											>
												AVANÇAR
											</button>
										</div>
									</div> -->

									<hr />

									<b
										><span class="step-label">Passo 2</span> - Faça upload do
										arquivo já preenchido</b
									>

									<p>
										Assim que preencher o arquivo com todos os alunos da turma
										faça o envio do aquivo
									</p>

									<!-- <b>Confirme os cadastros enviados abaixo:</b> -->

									<div class="upload-box" v-if="uploadItens.length == 0">
										<a
											v-on:click="clickOnFile()"
											href="#"
											class="btn btn-primary btn-medium cancel"
										>
											SELECIONAR ARQUIVO PARA ENVIO
											<!-- Selecionar arquivo para envio -->
											<span class="fa fa-file"></span
										></a>
										<input
											type="file"
											id="files"
											class="hide"
											ref="files"
											v-on:change="handleFileUpload()"
										/>

										<h3>
											Faça o envio da versão mais atualizada do seu arquivo de
											alunos
										</h3>
									</div>

									<div class="upload-box-list" v-if="uploadItens.length > 0">
										<div class="flex xs12 header-table">
											<div class="va-row">
												<div class="flex xs12 text-left">
													Confirme o cadastro dos dados abaixo:
												</div>
											</div>
										</div>

										<div
											class="va-row"
											v-for="studentItem in uploadItens"
											:key="studentItem.id"
										>
											<div class="flex xs12 body-student">
												<div class="va-row">
													<div class="flex xs7 student-name">
														{{ studentItem.name }}
													</div>
													<div class="flex xs5 text-right">
														{{ studentItem.birthdate }}
													</div>
												</div>
											</div>
										</div>

										<!-- <div class="va-row">
											<div
												id="btn-wait-import"
												class="flex xs4 text-right no-padding"
											>
												<button @click="confirmImport()" class="btn right">
													CONFIRMAR CADASTRO
												</button>
											</div>
										</div> -->
									</div>
								</div>

								<!-- <div></div> -->

								<!-- <div class="panel" v-if="uploadStep === 2">
									<div class="upload-box">
										<h3>
											Faça o envio da versão mais atualizada do seu arquivo de
											alunos
										</h3>
										<a
											v-on:click="clickOnFile()"
											href="#"
											class="btn btn-primary cancel"
											><span class="fa fa-chevron-up"></span> ENVIAR</a
										>
										<input
											type="file"
											id="files"
											class="hide"
											ref="files"
											v-on:change="handleFileUpload()"
										/>
									</div>
								</div> -->

								<div class="panel" v-if="uploadItens.length > 0">
									<!-- <div class="upload-box last-item">
										<div
											class="flex xs12 header-table"
											v-if="grade.students.length > 0"
										>
											<div class="va-row">
												<div class="flex xs8 text-left">
													Confirme o cadastro dos dados abaixo:
												</div>
												<div
													id="btn-wait-import"
													class="flex xs4 text-right no-padding"
												>
													<button @click="confirmImport()" class="btn right">
														CONFIRMAR CADASTRO
													</button>
												</div>
											</div>
										</div>

										<div
											class="va-row"
											v-for="studentItem in uploadItens"
											:key="studentItem.id"
										>
											<div class="flex xs12 body-student">
												<div class="va-row">
													<div class="flex xs7 student-name">
														{{ studentItem.name }}
													</div>
													<div class="flex xs5 text-right">
														{{ studentItem.birthdate }}
													</div>
												</div>
											</div>
										</div>
									</div> -->

									<div class="flex xs12 no-padding-right">
										<div class="error text-center">
											{{ errors.first("error") }}
										</div>
										<button
											id="btn-confirImport"
											@click="confirmImport()"
											class="btn right"
										>
											CONFIRMAR CADASTRO
										</button>
										<button
											type="button"
											@click="cancelImport()"
											class="btn btn-primary right cancel"
										>
											CANCELAR
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mask } from "vue-the-mask";
	import { mapGetters } from "vuex";

	const axios = require("axios");

	export default {
		name: "schools",
		directives: { mask },
		computed: {
			...mapGetters({
				teacherCredential: "teacherCredential",
				// ...
			}),
		},

		mounted() {
			const self = this;
		},

		props: ["grade"],

		data() {
			const months = [
				{
					value: "Janeiro",
					key: 1,
				},
				{
					value: "Fevereiro",
					key: 2,
				},
				{
					value: "Março",
					key: 3,
				},
				{
					value: "Abril",
					key: 4,
				},
				{
					value: "Maio",
					key: 5,
				},
				{
					value: "Junho",
					key: 6,
				},
				{
					value: "Julho",
					key: 7,
				},
				{
					value: "Agosto",
					key: 8,
				},
				{
					value: "Setembro",
					key: 9,
				},
				{
					value: "Outubro",
					key: 10,
				},
				{
					value: "Novembro",
					key: 11,
				},
				{
					value: "Dezembro",
					key: 12,
				},
			];

			const maxAgeAllowed = 16;

			const showBirthDay = false; // hardcoded hide birthday data

			const days = [];
			const years = [];
			const currentYear = new Date().getFullYear();

			for (let day = 1; day <= 31; day++) days.push({ key: day, value: day });
			for (let i = 0; i < maxAgeAllowed; i++) {
				years.push({ key: currentYear - i, value: currentYear - i });
			}
			years.push({ key: "outro", value: "Outro" });

			return {
				days: days,
				months: months,
				years: years,

				maxAgeAllowed: maxAgeAllowed,

				selectedBirthDay: null,
				selectedBirthMonth: null,
				selectedBirthYear: null,

				student: {
					name: "",
					grade_id: this.grade.id,
					birthdate: "",
					//"gender": this.gender
				},

				upload: false,
				uploadStep: 1,

				uploadItens: [],
				importId: null,

				isOtherYear: false,

				showBirthDay: showBirthDay,

				postStudentIsExection: false,
			};
		},
		methods: {
			clickOnFile() {
				this.$refs.files.click();
			},

			handleFileUpload() {
				this.uploadItens = [];
				this.files = this.$refs.files.files;
				this.submitFiles();
			},

			submitFiles() {
				const self = this;

				let formData = new FormData();

				for (var i = 0; i < this.files.length; i++) {
					let file = this.files[i];
					formData.append("csv", file);
				}

				axios
					.post(
						process.env.VUE_APP_ROOT_API + "/grades/import/" + this.grade.id,
						formData,
						{
							headers: {
								"Content-Type": "multipart/form-data",
								Authorization: "Bearer " + this.teacherCredential.token,
							},
						}
					)
					.then(function (response) {
						//alert('Upload feito com sucesso');
						self.uploadItens = response.data.data.data;
						self.importId = response.data.data.import_id;

						// self.changeStepsUpload(3);
					})
					.catch(function () {
						alert("Erro o envio falhou");
					});
			},

			changeGender(gender) {
				this.student.gender = gender;
			},

			changeStepsUpload(step) {
				this.uploadStep = step;
			},

			changeUploadStatus(status) {
				this.upload = status;
			},

			onChangeDay(event) {
				this.selectedBirthDay = event.target.value;
			},

			onChangeMonth(event) {
				this.selectedBirthMonth = event.target.value;
				this.validateDays({
					month: parseInt(event.target.value),
				});
			},

			onChangeYear(event) {
				if (event.target.value === "outro") {
					this.isOtherYear = true;
					this.selectedBirthYear = "";
				} else {
					this.selectedBirthYear = event.target.value;
				}

				this.validateDays({
					year: parseInt(event.target.value),
				});
			},

			onChangeGender(event) {
				this.student.gender = event.target.value;
			},

			saveStudent() {
				const self = this;
				this.$validator.validateAll().then((result) => {
					if (
						self.selectedBirthDay === null ||
						self.selectedBirthMonth === null ||
						self.selectedBirthYear === null
					) {
						this.selectedBirthYear = 2020;
						this.selectedBirthMonth = "01";
						this.selectedBirthDay = "01";
					}

					if (result) {
						let config = {
							headers: {
								Authorization: "Bearer " + this.teacherCredential.token,
							},
						};

						let url = process.env.VUE_APP_ROOT_API + "/grades/addStudent";

						let data = self.student;
						data.birthdate =
							this.selectedBirthYear +
							"-" +
							this.selectedBirthMonth +
							"-" +
							this.selectedBirthDay;

						//data.teacher = self.teacher
						if (self.postStudentIsExection == false) {
							self.postStudentIsExection = true;
							axios({
								method: "POST",
								url: url,
								headers: config.headers,
								data: data,
							})
								.then(function (response) {
									self.student = {
										name: "",
										grade_id: self.grade.id,
										birthdate: "",
									};

									(self.selectedBirthDay = null),
										(self.selectedBirthMonth = null),
										(self.selectedBirthYear = null),
										(self.isOtherYear = false),
										(event = {
											target: {
												value: null,
											},
										});

									self.onChangeDay(event);
									self.onChangeMonth(event);
									self.onChangeYear(event);

									self.grade.students.push(response.data.data.user);

									self.postStudentIsExection = false;
								})
								.catch(function (error) {
									let fieldError = {
										field: "error",
										msg: error.response.data.message,
										rule: "error", // optional
									};
									self.$validator.errors.add(fieldError);
									self.postStudentIsExection = false;
								});
						}
					}
				});
			},

			validateDays(payload) {
				const self = this;

				let month = payload.month ? payload.month : this.selectedBirthMonth;
				const year = payload.year ? payload.year : this.selectedBirthYear;

				if (!month) month = 13;

				let daysMonth;
				const newDays = [];

				if (
					month === 1 ||
					month === 3 ||
					month === 5 ||
					month === 7 ||
					month === 8 ||
					month === 10 ||
					month === 12 ||
					month === 13
				) {
					daysMonth = 31;
				} else if (month === 4 || month === 6 || month === 9 || month === 11) {
					daysMonth = 30;
				} else {
					if ((year % 4 === 0 && year % 100 > 0) || year % 400 === 0) {
						daysMonth = 29;
					} else {
						daysMonth = 28;
					}
				}

				if (!month && !year) daysMonth = 31;

				for (let day = 1; day <= daysMonth; day++)
					newDays.push({ key: day, value: day });

				self.days = newDays;
			},

			deleteStudent(id, index) {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + this.teacherCredential.token,
					},
				};

				if (confirm("Você deseja excluir este item?")) {
					axios
						.delete(
							process.env.VUE_APP_ROOT_API + "/grades/removeStudent/" + id,
							config
						)
						.then((response) => {
							self.grade.students.splice(index, 1);

							//self.$router.push({ name: 'schools' })
						})
						.catch((e) => {
							this.errors.push(e);
						});
				}
			},

			confirmImport() {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + self.teacherCredential.token,
					},
				};

				axios
					.get(
						process.env.VUE_APP_ROOT_API +
							"/grades/import_confirm/" +
							self.importId,
						config
					)
					.then((response) => {
						self.uploadStep = 1;
						self.upload = false;
						self.grade = response.data.data.grade;

						self.$router.push({ name: "grade" });
					})
					.catch((e) => {
						let fieldError = {
							field: "error",
							msg: error.response.data.message,
							rule: "error", // optional
						};
						self.$validator.errors.add(fieldError);
						this.errors.push(e);
					});
			},

			cancelImport() {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + self.teacherCredential.token,
					},
				};

				axios
					.get(
						process.env.VUE_APP_ROOT_API +
							"/grades/import_cancel/" +
							self.importId,
						config
					)
					.then((response) => {
						//self.uploadStep = 1;
						self.upload = false;
						// alert("Cancelou");
					})
					.catch((e) => {
						self.closeModal();
						this.errors.push(e);
					});
			},
		},
	};
</script>
<style lang="scss" scoped>
	.name-field {
		@media (max-width: 1280px) {
			flex-basis: 100%;
			flex-grow: 0;
			max-width: 100%;
		}
	}
	.birthday-field {
		@media (max-width: 1280px) {
			flex-basis: 100%;
			flex-grow: 0;
			max-width: 100%;
		}
	}
	.hide {
		display: none;
	}

	::v-deep .radio-button-icon {
		margin-left: 10px;
	}

	::v-deep .radio-button-wrapper {
		padding: 4px;
		height: 65px;
	}
	.upload-box {
		text-align: center;
		border-width: 2px;
		padding-bottom: 40px;
		padding-top: 72px;
		background: #f4f2fb;
		border: 1px dashed #afa2ba;
		border-radius: 10px;
	}
	::v-deep .select.form-group {
		// margin-bottom: 20px;
		padding: 20px 20px;
		margin-top: 0px;
	}
	.btn-trash {
		width: 45px;
		height: 45px;
		border-radius: 100px;
		background-color: #f8f7fc;
		color: #afa2ba;
		border: none;

		&:hover {
			background-color: red;
			color: #fff;
		}

		span {
			font-size: 22px;
		}
	}

	.no-padding-top {
		padding-top: 0px !important;
	}

	.tab-item {
		// border: 1px solid #ccc;
	}

	.phrase-secure {
		font-family: Roboto;
		font-size: 14px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.5;
		letter-spacing: normal;
		color: #68596e;
		margin-top: 20px !important;
		// margin-bottom:20px !important;

		b {
			color: #68596e !important;
		}

		// .btn {
		// 	height: 65px;
		// 	width: 100%;
		// 	max-width: 357px;
		// 	font-size: 14px;
		// 	border-radius: 100px;
		// 	line-height: 40px;
		// }
	}

	.custom-teacher-table {
		font-family: Roboto;
		font-size: 14px;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #afa2ba;

		b {
			font-size: 14px !important;
		}

		.header-table {
			height: 61.5px;
			background-color: #f8f7fc;
			border-top: solid 1px #dfdae4;
			line-height: 33px;
		}
	}

	.upload-box {
		.header-table {
			height: 61.5px;
			background-color: #f8f7fc;
			border-top: solid 1px #dfdae4;
			line-height: 33px;
			// margin-bottom: 20px;
		}

		&.last-item {
			padding-bottom: 0px;
		}
	}

	.teacher-flag {
		font-family: Roboto;
		font-size: 14px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #ffffff;
		background-color: #9378f0;
		line-height: 30px;

		a {
			font-weight: bold;
			text-decoration: underline;
			color: white;
		}
	}
	.book-covers {
		justify-content: flex-end;
	}
	.empty-book {
		width: 100%;
		border: 1px solid #afa2ba;
		height: 100%;
		max-height: 187px;
		justify-content: center;
		flex-direction: column;
		text-align: center;
		display: flex;
		font-size: 25px;
	}
	.btn-micro-edit {
		font-family: "Nunito";
		font-size: 12px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 2;
		letter-spacing: 0.67px;
		text-align: center;
		color: #4965bc;
		background: none;
		border: none;
	}
	.student-name {
		font-family: "Nunito";
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 19px;
		/* identical to box height */

		justify-content: center;
		flex-direction: column;
		text-align: left;
		display: flex;
	}
	.student-code {
		font-family: Nunito;
		font-size: 20px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.2;
		letter-spacing: 1.11px;
		text-align: right;
		color: #68596e;

		justify-content: center;
		flex-direction: column;
		text-align: right;
		display: flex;
	}

	.teacher-grade {
		background-color: white;
		font-family: Roboto;
		font-size: 16px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #68596e;

		.school-name-short {
			font-family: "Nunito";
			font-style: normal;
			font-weight: 900;
			font-size: 16px;
			line-height: 22px;
			text-transform: capitalize;
			color: #68596e;
			display: flex;
			align-items: center;
		}

		.btn {
			height: 45px;
			border-radius: 100px;
			background-color: #9378f0;
			background-image: none;
			position: relative;
			line-height: 1;
			padding: 16px 24px;
			padding-right: 48px;

			@media (max-width: 767px) {
				display: block;
				margin: 0 auto;
				top: 0px;
			}

			&:hover {
				background-color: #9378f0;
			}
			span {
				font-size: 17px;
				position: absolute;
				top: 13px;
				right: 23px;
			}
		}

		.teacher-name {
			line-height: 45px;
		}
	}

	.panel-gray {
		border-radius: 10px;
		border: solid 1px #dfdae4;
		background-color: #ffffff;
		overflow: hidden;

		.btn {
			border-radius: 100px;
			background-color: #4965bc;
			height: 65px;
			border-radius: 100px;
			background-color: #4965bc;
			padding-left: 70px;
			position: relative;
			font-size: 14px;
			margin-top: 20px;
			margin-bottom: 20px;

			span {
				width: 45px;
				height: 45px;
				border-radius: 100px;
				background-color: #ffffff;
				font-size: 41px;
				display: inline-block;
				color: #4965bc;
				line-height: 43px;
				position: absolute;
				left: 8px;
				top: 8px;
			}
		}
	}

	.body-teacher {
		margin-top: 20px;

		.add-title {
			font-family: "Nunito";
			font-style: normal;
			font-weight: 900;
			font-size: 26px;
			line-height: 35px;
			text-align: center;

			color: #68596e;
			text-align: center;
		}

		.body-spacing {
			background-color: white;
			border-radius: 0px 0px 10px 10px;
			padding-bottom: 15px;
			box-shadow: 0 10px 25px 0 #e2ddf6;
			z-index: 5;
			position: relative;
			padding-top: 2px;
		}

		.body-spacing .panel {
			padding: 10px 25px;

			h4 {
				margin-top: 10px;
				margin-bottom: 15px;
				font-family: Nunito;
				font-size: 20px;
				font-weight: 900;
				font-style: normal;
				font-stretch: normal;
				line-height: normal;
				letter-spacing: normal;
				color: #66596e;
			}
		}
	}

	.body-student {
		border-bottom: 1px solid #dfdae4;
	}

	.info-grade {
		padding-top: 20px;
		padding-bottom: 20px;
		font-family: "Roboto";
		font-size: 18px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #68596e;
	}

	.school-name {
		font-family: "Nunito";
		font-size: 40px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #ffffff;

		.back-circle {
			width: 60px;
			height: 60px;
			box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
			background-color: #ffffff;
			display: inline-block;
			text-align: center;
			border-radius: 30px;
		}
	}
	.my-account {
		position: absolute;
		top: 0px;
		background-color: #f8f7fc;
		z-index: 3;
		width: 100%;
		padding-bottom: 30px;
		min-height: 100%;
		overflow: hidden;
		// height: 100%;
	}

	.header-teacher {
		.ddh-item {
			font-family: Roboto;
			font-size: 12px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
			color: #fff;
		}

		.ddh-prof {
			font-family: Roboto;
			font-size: 12px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: right;
			color: #ffffff;
		}
	}

	h4 {
		font-family: "Nunito";
		font-size: 24px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.17;
		letter-spacing: 1.3px;
		color: #000000;
		margin-bottom: 0;
	}
	.header-menu {
		background-color: #00cf72;
		margin-bottom: 20px;
		display: block;

		h1 {
			font-family: "Nunito";
			font-size: 40px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.7;
			letter-spacing: normal;
			color: #000000;
			margin-top: 50px;
			margin-bottom: 20px;

			@media (max-width: 990px) {
				font-size: 18px;
			}
		}

		.logo-client {
			position: absolute;
			bottom: 0px;
			display: block;
			right: 0px;
			margin: 0px;
			float: right;

			@media (max-width: 990px) {
				display: none;
			}
		}

		.school-name {
			font-family: "Nunito";
			font-size: 40px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 10px;
			letter-spacing: normal;
			color: #ffffff;
			margin-bottom: 10px;
			margin-top: 10px;
			align-items: center;
			display: flex;

			h4 {
				font-family: "Nunito";
				font-size: 40px;
				font-weight: 900;
				font-style: normal;
				font-stretch: normal;
				letter-spacing: normal;
				color: #ffffff;
				margin-top: 0;
				display: flex;
				align-items: center;
				// line-height: 70px;
			}

			span {
				font-size: 16px;
				font-weight: 200 !important;
				margin-bottom: 10px;
				margin-top: 10px;
			}

			.back-circle {
				width: 60px;
				height: 60px;
				box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
				background-color: #ffffff;
				// display: inline-block;
				text-align: center;
				border-radius: 30px;
				position: relative;
				float: left;
				margin-right: 10px;
				top: 0px;
				margin-top: 0px;

				svg {
					position: absolute;
					left: 0px;
					right: 0px;
					top: 0px;
					bottom: 0px;
					margin: auto;
				}
			}
		}

		.header-teacher {
			position: relative;

			.ddh-item {
				font-family: Roboto;
				font-size: 12px;
				font-weight: bold;
				font-style: normal;
				font-stretch: normal;
				line-height: normal;
				letter-spacing: normal;
				color: #fff;
			}

			.ddh-prof {
				font-family: Roboto;
				font-size: 12px;
				font-weight: bold;
				font-style: normal;
				font-stretch: normal;
				line-height: normal;
				letter-spacing: normal;
				text-align: right;
				color: #ffffff;

				a {
					border: 0;
					background: none;
					font-family: Roboto;
					font-size: 12px;
					font-weight: bold;
					font-style: normal;
					font-stretch: normal;
					line-height: normal;
					letter-spacing: normal;
					text-align: right;
					color: #ffffff;
				}
			}
		}
	}

	.error-label {
		color: red;
		text-align: left;
		margin-bottom: 10px;
	}

	h5 {
		font-family: "Nunito";
		font-size: 20px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.17;
		letter-spacing: 1.3px;
		color: #000000;
	}

	.header-menu-club {
		h1 {
			font-family: "Nunito";
			font-size: 40px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.7;
			letter-spacing: normal;
			color: #000000;
			margin-top: 50px;
			margin-bottom: 20px;

			@media (max-width: 990px) {
				font-size: 18px;
			}
		}
	}

	// Default of page

	.address-row {
		position: relative;
	}

	.more-cards {
		.more-address {
			border-radius: 10px;
			border: solid 1px #f0ebf5;
			background-color: #ffffff;
		}
	}

	.panel {
		// height: 200px;

		overflow: hidden;
		margin-bottom: 20px;
		border-radius: 0px 0px 10px 10px;

		background-color: #ffffff;

		@media (max-width: 767px) {
			width: 100%;
			padding: 15px !important;
		}
		// border: solid 2px #9378f0;
		// box-shadow: 0 10px 25px 0 #e2ddf6;

		.header-panel {
			padding: 0px 20px;
			background-color: #9378f0;
			line-height: 33px;
			font-size: 16px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
			color: #ffffff;
		}

		.clube img {
			margin-top: -5px;
		}

		b {
			font-size: 16px;
			color: #000000;

			font-family: "Nunito";
			font-style: normal;
			font-weight: 900;
			font-size: 18px;
			line-height: 25px;
			/* identical to box height */
			color: #68596e;

			span {
				color: #4965bc;
			}
		}

		.panel-body {
			position: relative;
			padding: 10px 0px;
			font-family: Roboto;
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.5;
			letter-spacing: normal;
			color: #afa2ba;
			margin: 0px !important;

			.flex.xs1 {
				-ms-flex-preferred-size: 8.33333%;
				flex-basis: 6.33333%;
				-webkit-box-flex: 0;
				-ms-flex-positive: 0;
				flex-grow: 0;
				max-width: 6.33333%;
			}

			b {
				font-family: Roboto;
				font-size: 16px;
				font-weight: normal;
				font-style: normal;
				font-stretch: normal;
				line-height: 1.31;
				letter-spacing: normal;
				color: #000000;
			}

			.editor {
				right: 18px;
				top: 20%;
			}
		}
	}

	.subscription-box {
		// padding: 30px !important;

		font-family: Roboto;
		font-size: 16px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.31;
		letter-spacing: normal;
		color: #000000;

		.toggled {
			margin-top: 10px;
		}
	}
	.newsletter {
		// margin-top:40px;
		padding: 31px;
		border: solid 2px #f0ebf5;

		@media (max-width: 990px) {
			margin-top: 0px;
			padding: 15px;
		}
	}

	// Custom Component
	.v-switch-button {
		::v-deep &:before {
			content: "";
			display: block;
			position: relative;
			background-color: #fff;
			width: 6px;
			height: 6px;
			border-radius: 14.5px;
			margin-left: auto;
			margin-right: auto;
			top: 9px;
		}
	}

	.vue-js-switch {
		&.toggled {
			.v-switch-button {
				::v-deep &:before {
					content: "";
					display: block;
					position: relative;
					background-color: #7ed321;
					width: 6px;
					height: 6px;
					border-radius: 14.5px;
					margin-left: auto;
					margin-right: auto;
					top: 9px;
				}
			}
		}
	}

	.name-box {
		@media (max-width: 980px) {
			padding: 0px !important;
		}
	}
	.lastname-box {
		@media (max-width: 980px) {
			padding: 0px !important;
		}
	}

	.step-label {
	}

	.btn-medium {
		padding: 27px 25px;
		border-radius: 50px;
		background-color: transparent;
	}

	.body-spacing {
		hr {
			margin: 32px 0px;
		}

		h3 {
			font-size: 16px;
			line-height: 19px;
			color: #68596e;
		}
	}

	.upload-box-list {
		.header-table {
			padding: 0px !important;

			.flex {
				font-family: "Roboto";
				font-style: normal;
				font-weight: 700;
				font-size: 16px;
				line-height: 19px;
				/* identical to box height */

				color: #68596e;

				padding: 0px;
			}
		}
	}

	.body-student {
		padding: 24px !important;
		font-family: "Nunito";
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 19px;
		color: #68596e;
	}
</style>
